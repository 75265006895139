import React, { useState, useEffect } from "react";
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import PropTypes from 'prop-types';
import { FormattedMessage, useIntl } from 'gatsby-plugin-intl'
import Layout from "../components/layout";
import SEO from "../components/seo"
import Articles from '../components/articles';
import Position from '../components/open-positions';

// Styles
// import "../sass/main.scss"
import styles from "../sass/pages/contact.module.scss"

import uxUi from "../../src/assets/img/jobs-icons/ux-ui.svg"

let lang = ""

const goToURL = (e: any, url: string) => {
  window.location.assign(url)
  e.preventDefault()
}

const JoinUs = () => {

  lang = useIntl().locale;

  const data = useStaticQuery(graphql`
    query ImagesJoinUs {
      groupPicture: file( relativePath: { eq: "afterOfficePicture.jpg"} ) {
          id
          childImageSharp { 
            fixed {
              ...GatsbyImageSharpFixed
            }
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
      }


    }
  `);

  const title = useIntl().formatMessage({id: 'join-us'});  

  const [load] = useState(0);

  return (
    <Layout color="">
      <SEO 
      lang={lang}
      title={title}
      /> 
      <article className={ 'container-small ' + styles.article + " " + "space"}>
        <div className={"container-small" + " " + "centered"}>
            <h1><FormattedMessage id="join_us_title_1"/></h1>
            <p><FormattedMessage id="join_us_paragraph_1" values={{breakLine:<br/>}}/></p>
            <div className="space"></div>
            {/* <div className={"container-large grid-cards-4"}>

              <Link to={`/${lang}` + "/position"} className={styles.aplication}>
                <div className="whiteCardSquare whiteCardContact">
                    <div className="whiteCardIconMedium">
                        <img src={uxUi} alt="UX UI Designer" />
                    </div>
                    <p><FormattedMessage id="ux_ui"/></p>
                </div>
              </Link>

              <Link to={`/${lang}` + "/position"} className={styles.aplication}>
                <div className="whiteCardSquare whiteCardContact">
                    <div className="whiteCardIconMedium">
                        <img src={uxUi} alt="UX UI Designer" />
                    </div>
                    <p><FormattedMessage id="ux_ui"/></p>
                </div>
              </Link>

              <Link to={`/${lang}` + "/position"} className={styles.aplication}>
                <div className="whiteCardSquare whiteCardContact">
                    <div className="whiteCardIconMedium">
                        <img src={uxUi} alt="UX UI Designer" />
                    </div>
                    <p><FormattedMessage id="ux_ui"/></p>
                </div>
              </Link>

            </div> */}
            <Position/>
            <div className="space"></div>
            <div className="space"></div>
            <h1><FormattedMessage id="join_us_title_2"/></h1>
            </div>
            <div className="space"></div>
            <div className={"container-large"}>
              <div className={"grid-cards-2" + " " + "grid-vertical-centered"}>
                <div>
                  <figure className={styles.picture}>
                    <Img fluid={ data.groupPicture.childImageSharp.fluid } alt="After Office Picture" />
                  </figure>
                </div>

              <div>
                <p><FormattedMessage id="join_us_paragraph_2" values={{breakLine:<br/>}}/></p>
                {/* <Link to={`/${lang}` + "/join-us"}>
                  <button className="btn btn-main "><FormattedMessage id="check_out_our_team" /></button>
                </Link> */}
              </div>
            </div>
          </div>
          <div className="spaceLarge"></div>
        
      </article>
    </Layout>
  )
}

export default JoinUs;
