import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from "prop-types";
import { Link, FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';

import styles from "../sass/pages/contact.module.scss";
import uxUiIcon from "../../src/assets/img/jobs-icons/ux-ui.svg";
import mobileIcon from "../../src/assets/img/jobs-icons/mobile.svg";
import devIcon from "../../src/assets/img/jobs-icons/development.svg";



const Positions = ({ intl }: any) => {
    function renderSwitch(type: number){
        switch(type){
            case 1:
            return uxUiIcon;
            case 2:
            case 4:
            return devIcon;
            case 3:
            return mobileIcon;
            default:
            return devIcon;
        }
    }
    const data = useStaticQuery(graphql`
  query{
    allContentfulOpenPosition{
        edges{
          node{
            id
            type
            title
            slug
            experience_list
            responsabilities_list
            formation_text{
              id
              formation_text
            }
            node_locale
          }
        }
      }
    }
    `)
    return (
        <article className={"container-large grid-cards-4"}>
            {data.allContentfulOpenPosition.edges.map((edge: any) => {
                if (edge.node.node_locale === intl.locale) {
                    return (
                        <Link to={`/position/${edge.node.slug}`} className={styles.aplication}>
                            <div className="whiteCardSquare whiteCardContact">
                                <div className="whiteCardIconMedium">
                                    <img src={renderSwitch(edge.node.type)} alt={`position_${edge.node.title}`} />
                                </div>
                                <p>{edge.node.title}</p>
                            </div>
                        </Link>
                    )
                }
            }
            )
            }
        </article>
    )
}

export default injectIntl(Positions);